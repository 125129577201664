.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=submit] {

  margin: 5px;
  background-color: #1a1a1a;
  cursor: pointer;
  /*border-radius: 16px;*/
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

input[type=submit]:hover {

  background-color: #373b41;
  
}

input[type=text] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);
  background-color: #acc4ce;
}

input[type=password] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);;
  background-color: #acc4ce;
}

input[type=number] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);;
  background-color: #acc4ce;
}

.center{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}


table{
  margin-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {

  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even){background-color: #acc4ce;}

/* table tr:hover {background-color: #b6dff0;} */

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1a1a1a;
  color: white;
}

.table-button {
  margin: 5px;
  background-color: #1a1a1a;
  cursor: pointer;
  border-radius: 32px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.page-content {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
}
