ul.bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(61, 185, 39);
  border: 1px solid black;
}

ul.bar li.right {
  float: right;
}

ul.bar li {
  float: left;
}

img {
  width: 65px;
  height: 60px;
  padding-right: 10px;
  float: right;
}


/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}


/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}

.drop-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.node.drop:hover .dropdown-content {
  display: block;
}

.node {
  display: block;
  color: white;
  text-align: center;
  padding: 22px 28px;
  text-decoration: none;
  background-color: rgb(62, 179, 42);
  border: 1px solid black;
  border-style: ridge;
  transition: 0.3s ease-in;

  font-weight: 500;
  letter-spacing: 2px;
}

.node:hover {
  background-color: #555;
  color: white;
}

.node.active {
  background-color: #04aa6d;
}