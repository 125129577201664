body {
  background-color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input[type=submit] {

  margin: 5px;
  background-color: #1a1a1a;
  cursor: pointer;
  /*border-radius: 16px;*/
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

input[type=submit]:hover {

  background-color: #373b41;
  
}

input[type=text] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);
  background-color: #acc4ce;
}

input[type=password] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);;
  background-color: #acc4ce;
}

input[type=number] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);;
  background-color: #acc4ce;
}

.center{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}


table{
  margin-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {

  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even){background-color: #acc4ce;}

/* table tr:hover {background-color: #b6dff0;} */

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1a1a1a;
  color: white;
}

.table-button {
  margin: 5px;
  background-color: #1a1a1a;
  cursor: pointer;
  border-radius: 32px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.page-content {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
}

ul.bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(61, 185, 39);
  border: 1px solid black;
}

ul.bar li.right {
  float: right;
}

ul.bar li {
  float: left;
}

img {
  width: 65px;
  height: 60px;
  padding-right: 10px;
  float: right;
}


/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}


/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}

.drop-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.node.drop:hover .dropdown-content {
  display: block;
}

.node {
  display: block;
  color: white;
  text-align: center;
  padding: 22px 28px;
  text-decoration: none;
  background-color: rgb(62, 179, 42);
  border: 1px solid black;
  border-style: ridge;
  transition: 0.3s ease-in;

  font-weight: 500;
  letter-spacing: 2px;
}

.node:hover {
  background-color: #555;
  color: white;
}

.node.active {
  background-color: #04aa6d;
}
h1 {
  font-weight: bold;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-align: center;
}

body {
  background-color: rgb(250, 235, 215);
}

.watermark {
  opacity: 0.5;
  color: BLACK;
  position: fixed;
  top: auto;
  left: 80%;
}

body {
    background-color: rgb(250, 235, 215);
}
